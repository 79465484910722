@mixin trans($prop: all, $time: .3s, $easing: ease) { 
	transition: $prop $time $easing;
}

@mixin mq($range) { 
	$phone-upper-boundary: 600px;
	@if $range == sp { 
		@media all and (max-width: #{ $phone-upper-boundary - 1}) {  @content; }
	} @else if $range == pc { 
		@media all and (min-width: $phone-upper-boundary ) {  @content; }
	}
}