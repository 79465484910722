@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  tit
================================================ */
.mod_inner01{ 
	padding-left: 15px;
	padding-right: 15px;
	@include mq(pc) { 
		width: 1100px;
		margin: auto;
		padding-left: 0;
		padding-right: 0;
	}
}



/*
#styleguide
mod_inner01

SP:左右余白15px
PC:width: 1100px中央寄せ
```
<p class="mod_inner01">CONCEPT</p>
```
*/