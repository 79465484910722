@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  box
================================================ */
.mod_box{ 

}


/*
#styleguide
mod_box01

mod_box01
```

```
*/