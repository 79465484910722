@charset "UTF-8";
/*=============================================================
 04_modules
=============================================================*/
/*!  button
================================================ */
.mod_btn01 {  display: table; width: 100%; }

.mod_btn01 a {  display: table-cell; vertical-align: middle; height: 55px; text-decoration: none; font-size: 1.6rem; font-weight: 500; text-align: center; color: #fff; background-color: #69412b; box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2); border-radius: 6px; position: relative; }

.mod_btn01 a:before, .mod_btn01 a:after {  position: absolute; right: 15px; }

.mod_btn01 a:before {  content: ''; width: 12px; height: 12px; border-radius: 50%; background: #fff; top: 22px; }

.mod_btn01 a:after {  content: ''; box-sizing: border-box; width: 3px; height: 3px; border: 3px solid transparent; border-left: 5px solid #69412b; top: 25px; }

.mod_btn01.is_down a {  height: 50px; line-height: 1.2; }

.mod_btn01.is_down a:before {  top: 18px; }

.mod_btn01.is_down a:after {  top: 23px; right: 17px; transform: rotate(90deg); }

.mod_btn01.is_left a:before, .mod_btn01.is_left a:after {  right: auto; left: 15px; }

.mod_btn01.is_left a:after {  transform: rotate(180deg); }

@media all and (min-width: 600px) {  .mod_btn01 a {  height: 80px; font-size: 1.7rem; }
  .mod_btn01 a:before, .mod_btn01 a:after {  right: 30px; }
  .mod_btn01 a:before {  width: 16px; height: 16px; top: 32px; }
  .mod_btn01 a:after {  width: 4px; height: 4px; border: 4px solid transparent; border-left: 6px solid #69412b; top: 36px; }
  .mod_btn01.is_down a {  height: 60px; }
  .mod_btn01.is_down a:before {  top: 22px; }
  .mod_btn01.is_down a:after {  top: 29px; right: 33px; }
  .mod_btn01.is_left a:before, .mod_btn01.is_left a:after {  left: 30px; } }

.mod_btn02 a {  display: block; text-decoration: none; font-size: 1.6rem; font-weight: 500; color: #69412b; box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2); border-radius: 6px; position: relative; border: 2px solid #d8d8ce; padding: 17px 30px 17px 13px; line-height: 1.3; }

.mod_btn02 a:before, .mod_btn02 a:after {  position: absolute; right: 15px; }

.mod_btn02 a:before {  content: ''; width: 12px; height: 12px; border-radius: 50%; background: #69412b; top: 45%; }

.mod_btn02 a:after {  content: ''; box-sizing: border-box; width: 3px; height: 3px; border: 3px solid transparent; border-left: 5px solid #fff; top: 48%; }

@media all and (min-width: 600px) {  .mod_btn02 a {  font-size: 1.5rem; border-radius: 4px; padding: 23px 60px 23px 30px; line-height: 1.7; }
  .mod_btn02 a:before, .mod_btn02 a:after {  right: 20px; }
  .mod_btn02 a:before {  width: 16px; height: 16px; top: 40%; }
  .mod_btn02 a:after {  width: 4px; height: 4px; border: 4px solid transparent; border-left: 6px solid #fff; top: 45%; } }

/*
#styleguide
mod_btn01

is_downで下向き矢印
is_leftで矢印の位置を左
```
<p class="btn mod_btn01"><a href="/service/" class="alpha">業務内容の詳細はこちら</a></p>

<ul class="anchor">
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">相続・贈与・<br class="viewSp">遺言書</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">不動産登記</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">民事信託・<br class="viewSp">家族信託</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">成年後見</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">法人業務</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">裁判事務</a></li>
</ul>

<p class="mod_btn01 is_left"><a href="#" class="alpha">新着情報一覧へ戻る</a></p>
```
*/
/*
#styleguide
mod_btn02

mod_btn02
```
<p class="mod_btn02"><a href="#" class="alpha">記事のタイトルが入ります記事のタイトルが入ります記事のタイトルが入ります</a></p>
```
*/
/*!  pager
================================================ */
/*
#styleguide
mod_pagination01

pager

```

```
*/
/*!  tit
================================================ */
.mod_tit01 {  font-size: 2.5rem; text-align: center; position: relative; margin: 0 -15px; line-height: 1.8; letter-spacing: 0.18em; }

.mod_tit01:before {  content: ''; background-color: #d8d8ce; width: 100%; height: 1px; position: absolute; top: 50%; left: 0; }

.mod_tit01_in {  font-weight: 500; position: relative; z-index: 1; padding: 0 10px; }

.mod_tit01_in.is_bgcolor01 {  background-color: #f6faf5; }

.mod_tit01_in.is_bgcolor02 {  background-color: #fff; }

@media all and (min-width: 600px) {  .mod_tit01 {  font-size: 3.6rem; margin: 0; line-height: 1.7; }
  .mod_tit01_in {  padding: 0 25px; } }

.mod_tit01_eng {  color: #bab5a7; font-size: 1.0rem; text-align: center; font-weight: 500; }

@media all and (min-width: 600px) {  .mod_tit01_eng {  font-size: 1.3rem; } }

.mod_tit02_head {  border-top: 3px solid #0f6b4d; border-bottom: 2px solid #d8d8ce; padding: 10px; }

@media all and (min-width: 600px) {  .mod_tit02_head {  border-top: 4px solid #0f6b4d; border-bottom: 1px solid #d8d8ce; padding: 15px 20px; } }

.mod_tit02 {  font-size: 1.9rem; font-weight: 500; background: #fff url(../img/common/ico_mod_tit01.png) 0 0 no-repeat; background-size: 7px 100%; padding-left: 25px; line-height: 1.3; }

@media all and (min-width: 600px) {  .mod_tit02 {  font-size: 3.0rem; background: #fff url(../img/common/ico_mod_tit01.png) 0 0 no-repeat; background-size: 9px 100%; } }

.mod_tit03 {  font-size: 1.7rem; font-weight: 500; background: url(../img/common/ico_mod_tit01.png) 0 0 no-repeat; background-size: 7px 100%; padding-left: 15px; line-height: 1.3; }

@media all and (min-width: 600px) {  .mod_tit03 {  font-size: 2.4rem; background: url(../img/common/ico_mod_tit01.png) 0 0 no-repeat; background-size: 9px 100%; padding-left: 25px; } }

.mod_tit04 {  font-size: 1.6rem; font-weight: 500; line-height: 1.3; border-bottom: 1px solid #257e61; padding-bottom: 10px; }

@media all and (min-width: 600px) {  .mod_tit04 {  font-size: 2.0rem; padding-bottom: 15px; } }

/*
#styleguide
mod_tit01　mod_tit01_in

mod_tit01_inのクラスにis_bgcolor01　is_bgcolor02
で背景色変更
```
<h2 class="tit mod_tit01"><span class="mod_tit01_in is_bgcolor01 serif">業務内容</span></h2>
<h2 class="tit mod_tit01"><span class="mod_tit01_in is_bgcolor02 serif">業務内容</span></h2>
```
*/
/*
#styleguide
mod_tit01_eng

mod_tit01_eng
```
<p class="mod_tit01_eng roboto">SERVICE</p>
```
*/
/*
#styleguide
mod_tit02_head mod_tit02

mod_tit02_head mod_tit02
```
<div class="mod_tit02_head">
	<h2 class="mod_tit02 serif">相続・贈与・遺言書</h2>
</div>
```
*/
/*
#styleguide
mod_tit03

mod_tit03
```
<h3 class="mod_tit03 serif">タイトルが入りますタイトルが入ります</h3>
```
*/
/*
#styleguide
mod_tit04

mod_tit04
```
<h4 class="mod_tit04 serif">タイトルが入りますタイトルが入ります</h4>
```
*/
/*!  box
================================================ */
/*
#styleguide
mod_box01

mod_box01
```

```
*/
/*!  tit
================================================ */
.mod_inner01 {  padding-left: 15px; padding-right: 15px; }

@media all and (min-width: 600px) {  .mod_inner01 {  width: 1100px; margin: auto; padding-left: 0; padding-right: 0; } }

/*
#styleguide
mod_inner01

SP:左右余白15px
PC:width: 1100px中央寄せ
```
<p class="mod_inner01">CONCEPT</p>
```
*/
