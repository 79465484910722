@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  tit
================================================ */
.mod_tit01{ 
	font-size: 2.5rem;
	text-align: center;
	position: relative;
	margin: 0 -15px;
	line-height: 1.8;
	letter-spacing: 0.18em;
	&:before{ 
		content: '';
		background-color: #d8d8ce;
		width: 100%;
		height: 1px;
		position: absolute;
		top: 50%;
		left: 0;
	}
	&_in{ 
		font-weight: 500;
		position: relative;
		z-index: 1;
		padding: 0 10px;
		&.is_bgcolor01{ 
			background-color: #f6faf5;
		}
		&.is_bgcolor02{ 
			background-color: #fff;
		}
	}
	@include mq(pc) { 
		font-size: 3.6rem;
		margin: 0;
		line-height: 1.7;
		&_in{ 
			padding: 0 25px;
		}
	}
}

.mod_tit01_eng{ 
	color: #bab5a7;
	font-size: 1.0rem;
	text-align: center;
	font-weight: 500;
	@include mq(pc) { 
		font-size: 1.3rem;
	}
}

.mod_tit02_head{ 
	border-top: 3px solid #0f6b4d;
	border-bottom: 2px solid #d8d8ce;
	padding: 10px;
	@include mq(pc) { 
		border-top: 4px solid #0f6b4d;
		border-bottom: 1px solid #d8d8ce;
		padding: 15px 20px;
	}
}
.mod_tit02{ 
	font-size: 1.9rem;
	font-weight: 500;
	background: #fff url(../img/common/ico_mod_tit01.png) 0 0 no-repeat;
	background-size: 7px 100%;
	padding-left: 25px;
	line-height: 1.3;
	@include mq(pc) { 
		font-size: 3.0rem;
		background: #fff url(../img/common/ico_mod_tit01.png) 0 0 no-repeat;
		background-size: 9px 100%;
	}
}

.mod_tit03{ 
	font-size: 1.7rem;
	font-weight: 500;
	background: url(../img/common/ico_mod_tit01.png) 0 0 no-repeat;
	background-size: 7px 100%;
	padding-left: 15px;
	line-height: 1.3;
	@include mq(pc) { 
		font-size: 2.4rem;
		background: url(../img/common/ico_mod_tit01.png) 0 0 no-repeat;
		background-size: 9px 100%;
		padding-left: 25px;
	}
}

.mod_tit04{ 
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.3;
	border-bottom: 1px solid #257e61;
	padding-bottom: 10px;
	@include mq(pc) { 
		font-size: 2.0rem;
		padding-bottom: 15px;
	}
}


/*
#styleguide
mod_tit01　mod_tit01_in

mod_tit01_inのクラスにis_bgcolor01　is_bgcolor02
で背景色変更
```
<h2 class="tit mod_tit01"><span class="mod_tit01_in is_bgcolor01 serif">業務内容</span></h2>
<h2 class="tit mod_tit01"><span class="mod_tit01_in is_bgcolor02 serif">業務内容</span></h2>
```
*/

/*
#styleguide
mod_tit01_eng

mod_tit01_eng
```
<p class="mod_tit01_eng roboto">SERVICE</p>
```
*/

/*
#styleguide
mod_tit02_head mod_tit02

mod_tit02_head mod_tit02
```
<div class="mod_tit02_head">
	<h2 class="mod_tit02 serif">相続・贈与・遺言書</h2>
</div>
```
*/

/*
#styleguide
mod_tit03

mod_tit03
```
<h3 class="mod_tit03 serif">タイトルが入りますタイトルが入ります</h3>
```
*/

/*
#styleguide
mod_tit04

mod_tit04
```
<h4 class="mod_tit04 serif">タイトルが入りますタイトルが入ります</h4>
```
*/

