@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  pager
================================================ */
.mod_pager01 { 
}
/*
#styleguide
mod_pagination01

pager

```

```
*/

