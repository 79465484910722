@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  button
================================================ */
.mod_btn01{ 
	display: table;
	width: 100%;
	a{ 
		display: table-cell;
		vertical-align: middle;
		height: 55px;
		text-decoration: none;
		font-size: 1.6rem;
		font-weight: 500;
		text-align: center;
		color: #fff;
		background-color: #69412b;
		box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2);
		border-radius: 6px;
		position: relative;
		&:before,
		&:after{ 
			position: absolute;
			right: 15px;
		}
		&:before{ 
			content: '';
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #fff;
			top: 22px;
		}
		&:after{ 
			content: '';
			box-sizing: border-box;
			width: 3px;
			height: 3px;
			border: 3px solid transparent;
			border-left: 5px solid #69412b;
			top: 25px;
		}
	}
	&.is_down{ 
		a{ 
			height: 50px;
			line-height: 1.2;
			&:before{ 
				top: 18px;
			}
			&:after{ 
				top: 23px;
				right: 17px;
				transform: rotate(90deg);
			}
		}
	}
	&.is_left{ 
		a{ 
			&:before,
			&:after{ 
				right: auto;
				left: 15px;
			}
			&:after{ 
				transform: rotate(180deg);
			}
		}
	}
	@include mq(pc) { 
		a{ 
			height: 80px;
			font-size: 1.7rem;
			&:before,
			&:after{ 
				right: 30px;
			}
			&:before{ 
				width: 16px;
				height: 16px;
				top: 32px;
			}
			&:after{ 
				width: 4px;
				height: 4px;
				border: 4px solid transparent;
				border-left: 6px solid #69412b;
				top: 36px;
			}
		}
		&.is_down{ 
			a{ 
				height: 60px;
				&:before{ 
					top: 22px;
				}
				&:after{ 
					top: 29px;
					right: 33px;
				}
			}
		}
		&.is_left{ 
			a{ 
				&:before,
				&:after{ 
					left: 30px;
				}
			}
		}
	}
}


.mod_btn02{ 
	a{ 
		display: block;
		text-decoration: none;
		font-size: 1.6rem;
		font-weight: 500;
		color: #69412b;
		box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2);
		border-radius: 6px;
		position: relative;
		border: 2px solid #d8d8ce;
		padding: 17px 30px 17px 13px;
		line-height: 1.3;
		&:before,
		&:after{ 
			position: absolute;
			right: 15px;
		}
		&:before{ 
			content: '';
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #69412b;
			top: 45%;
		}
		&:after{ 
			content: '';
			box-sizing: border-box;
			width: 3px;
			height: 3px;
			border: 3px solid transparent;
			border-left: 5px solid #fff;
			top: 48%;
		}
	}
	@include mq(pc) { 
		a{ 
			font-size: 1.5rem;
			border-radius: 4px;
			padding: 23px 60px 23px 30px;
			line-height: 1.7;
			&:before,
			&:after{ 
				right: 20px;
			}
			&:before{ 
				width: 16px;
				height: 16px;
				top: 40%;
			}
			&:after{ 
				width: 4px;
				height: 4px;
				border: 4px solid transparent;
				border-left: 6px solid #fff;
				top: 45%;
			}
		}
	}
}



/*
#styleguide
mod_btn01

is_downで下向き矢印
is_leftで矢印の位置を左
```
<p class="btn mod_btn01"><a href="/service/" class="alpha">業務内容の詳細はこちら</a></p>

<ul class="anchor">
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">相続・贈与・<br class="viewSp">遺言書</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">不動産登記</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">民事信託・<br class="viewSp">家族信託</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">成年後見</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">法人業務</a></li>
	<li class="item mod_btn01 is_down"><a href="#" class="alpha">裁判事務</a></li>
</ul>

<p class="mod_btn01 is_left"><a href="#" class="alpha">新着情報一覧へ戻る</a></p>
```
*/

/*
#styleguide
mod_btn02

mod_btn02
```
<p class="mod_btn02"><a href="#" class="alpha">記事のタイトルが入ります記事のタイトルが入ります記事のタイトルが入ります</a></p>
```
*/
